var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"invoice-preview-card"},[_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('h2',{staticClass:"my-auto"},[_vm._v(" "+_vm._s(_vm.$t("customer_information.faq.edit_title"))+" "),_c('br')]),_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.$router.push({ name: 'faqs-list' })}}},[_c('i',{staticClass:"las la-arrow-left mr-50"}),_c('span',[_vm._v(_vm._s(_vm.$t("button.back_to_list")))])])],1)]),_c('b-card',[_c('validation-observer',{ref:"formRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('customer_information.faq.filter_label_question'),"label-cols-md":"12","label-for":"title"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( 'customer_information.faq.filter_label_question' ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":_vm.$t(
                'customer_information.faq.create_input_question_placeholder'
              ),"type":"text"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t(
            'customer_information.information_content.create_input_sector'
          ),"label-cols-md":"12","label-for":"mots-cles"}},[_c('v-select',{attrs:{"id":"roles","placeholder":_vm.$t(
              'customer_information.information_content.create_input_sector_placeholder'
            ),"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","reduce":function (option) { return option.id; },"options":_vm.listSecteurs},model:{value:(_vm.form.faq_section_id),callback:function ($$v) {_vm.$set(_vm.form, "faq_section_id", $$v)},expression:"form.faq_section_id"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('customer_information.faq.create_label_response_title'),"label-cols-md":"12","label-for":"descripion"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t( 'customer_information.faq.create_label_response_title' ))+" "),_c('RequiredStar')],1)]},proxy:true}])},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('vue-editor',{attrs:{"editor-toolbar":_vm.customToolbar,"placeholder":_vm.$t(
                'customer_information.faq.create_input_response_placeholder'
              )},model:{value:(_vm.form.response),callback:function ($$v) {_vm.$set(_vm.form, "response", $$v)},expression:"form.response"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isCreatingProduct},on:{"click":function($event){return _vm.updateFaq()}}},[(_vm.isCreatingProduct == false)?_c('feather-icon',{attrs:{"icon":"SaveIcon"}}):_vm._e(),(_vm.isCreatingProduct == true)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v("   "+_vm._s(_vm.$t("button.edit_form"))+" ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }